import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { Ticket } from '@ttstr/api/products';
import { Container, ProductDetailHeader, TicketMarkup, VariantChooser, VariantListing, ExtraTncInfo, NativeSlider
} from '@ttstr/components';
import MerchantInfo from '@ttstr/components/ProductDetail/MerchantInfo';
import ProductInfoBar from './ProductInfoBar';


interface OwnProps {
  readonly product: Ticket;
}

type Props = Readonly<OwnProps>;

const TicketDetail: React.FC<Props> = ({ product }) => {
  const { t } = useTranslation();

  const images = React.useMemo(() => [product.hero_image.url, ...product.gallery_image_urls.map((i) => i.gallery)], [
    product,
  ]);

  return (
    <article className="product-detail" itemScope itemType="http://schema.org/Event">
      <Helmet>
        <body className="ticket-detail-page" />
      </Helmet>
      <TicketMarkup ticket={product} />

      {/* <ProductDetailHeader
        className="product-header"
        background={product.image.productthumb.url}
        image={product.image.productthumb.url}
      /> */}

      <div className="product-content">

        <Container fluid className="ticket-detail">

          <Row className='justify-content-center'>

            {product.hero_image.url && (
            <Col lg="12" className="p-0">
              <NativeSlider
                images={images.length ? images : [product.hero_image.url]}
                // thumbs={thumbs.length ? thumbs : [product.hero_image.thumb.url]}
                // thumbContainerClassName="random-rotation scribble-hover-overlay"
                // thumbClassName="random-rotation-object"
              />
            </Col>
            )}

            <Col lg="4" className="order-xl-last">
            <header className="text-left text-lg-left">
              <h1 className="display-5 my-2 mt-0 mt-lg-5">
                {product.supertitle && <span className="text-muted product-supertitle">{product.supertitle}</span>}
                <span itemProp="name" className="product-title">
                  {product.title}
                </span>
                <small className="product-subtitle">{product.subtitle}</small>
              </h1>
            </header>

            <ProductInfoBar product={product} />

            <VariantChooser product={product} className="mb-5" />
            {product.description && (
              <Row>
                <Col>
                  <h3 className="product-title">{t(`PRODUCT.INFORMATION`)}</h3>
                  <div dangerouslySetInnerHTML={{ __html: product.description }} itemProp="description" />
                </Col>
              </Row>
              
            )}
             
              {product.merchant_id && <MerchantInfo merchantId={product.merchant_id} />}
              {!!product.extra_tnc_ids.length && <ExtraTncInfo extraTncIds={product.extra_tnc_ids} />}


            </Col>
          </Row>
        </Container>
      </div>
    </article>
  );
};

export default React.memo(TicketDetail);
